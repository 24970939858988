import React from "react";
import { graphql } from "gatsby";
import { TemplatePage } from "presentation/Layout/TemplatePage";
import { Home } from "presentation/screens";
import SEO from "presentation/Seo";
import { IndexPageParams } from "_types/IHome";
import { ThemeProvider } from "presentation/Layout/ThemeProvider";
import {
  getDataFooter,
  getDataMenu,
  getDataSearchMenu
} from "_utils/assemblePagesData";
import { WEBSITE_URL } from "_utils/constants";

function IndexPage({ data }: IndexPageParams) {
  const { menu, title, urlKnowMore } = getDataMenu({
    data: data?.menu?.data?.body
  });
  const isExistSpecialties =
    data?.specialties?.nodes && data?.specialties?.nodes.length > 0;
  const specialties = isExistSpecialties
    ? data?.specialties.nodes.map(item => ({
        label: item?.data?.nome_da_especialidade.richText[0].text,
        icon: item?.data?.icone_da_especialidade?.url,
        url: item?.url
      }))
    : [];
  const sectionHero = {
    imageHero: data?.contentHome?.data?.foto_destaque,
    title: data?.contentHome?.data?.titulo_da_pagina?.richText,
    description: data?.contentHome?.data?.descricao_curta?.richText,
    news: data?.contentHome?.data?.novidades
  };

  const sectionQuality = {
    title: data?.sectionQuality?.data?.titulo_da_secao?.richText,
    description: data?.sectionQuality?.data?.descricao_curta?.richText,
    image: data?.sectionQuality?.data?.imagem_destacada,
    certificates: data?.sectionQuality?.data?.certificates[0].items.map(
      item => ({
        name: item?.nome_do_certificado,
        urlDownload: item?.anexo_do_certificado?.url
      })
    )
  };
  const searchMenu = getDataSearchMenu({
    searchExams: data?.searchExams,
    ctaContato: data?.ctaContato,
    allExams: data?.allExams,
    urlSearchExam: menu?.link_busca_exames?.uid
  });

  const footerData = getDataFooter({ data: data?.menu?.data?.body });

  const dataSEO = {
    canonicalUrl: `${WEBSITE_URL}${data?.contentHome?.data?.canonical_url?.text}`,
    noFollow: data?.contentHome?.data?.no_follow,
    noIndex: data?.contentHome?.data?.no_index,
    openGraph: data?.contentHome?.data?.open_graph[0],
    telefone1: data?.contentContacts?.data?.telefone_1?.text,
    email: data?.contentContacts?.data?.email?.text,
    imagesPage: [
      {
        url: data?.contentHome?.data?.foto_destaque?.url,
        width: Number(
          data?.contentHome?.data?.foto_destaque?.gatsbyImageData?.width ?? 500
        ),
        height: Number(
          data?.contentHome?.data?.foto_destaque?.gatsbyImageData?.height ?? 400
        ),
        alt: data?.contentHome?.data?.foto_destaque?.alt
      }
    ],
    metaTags: [
      {
        property: "application-name",
        content: "GatsbySeo"
      },
      {
        property: "og:title",
        content:
          data?.contentHome?.data?.open_graph[0]?.og_title?.text ??
          "sem conteúdo"
      },
      {
        property: "og:url",
        content: `${WEBSITE_URL}${data?.contentHome?.data?.canonical_url?.text}`
      },
      {
        property: "og:image",
        content: data?.contentHome?.data?.open_graph[0]?.og_image?.url
      },
      {
        property: "og:type",
        content: "website"
      }
    ]
  };

  return (
    <ThemeProvider>
      <SEO {...dataSEO} />
      <TemplatePage
        menu={menu}
        title={title}
        urlKnowMore={urlKnowMore}
        specialties={specialties}
        searchExams={searchMenu}
        initialStyle="light"
        footerData={footerData}
        contact={data?.contact}
      >
        <Home
          sectionHero={sectionHero}
          sectionQuality={sectionQuality}
          sliceZoneData={data?.contentHome?.data?.body}
        />
      </TemplatePage>
    </ThemeProvider>
  );
}

export const query = graphql`
  query GetDataHome {
    menu: prismicMenuFooter {
      ...MenuFragment
    }
    contact: prismicContato {
      ...Contact
    }
    contentHome: prismicHomepage {
      data {
        no_follow
        no_index
        novidades {
          categoria_da_novidade {
            text
          }
          link {
            uid
            type
            url
            document {
              ... on PrismicBuscarExames {
                last_publication_date(locale: "pt-BR")
                first_publication_date(locale: "pt-BR")
              }
              ... on PrismicEspecialidade {
                last_publication_date(locale: "pt-BR")
                first_publication_date(locale: "pt-BR")
              }
              ... on PrismicExames {
                last_publication_date(locale: "pt-BR")
                first_publication_date(locale: "pt-BR")
              }
              ... on PrismicPaginaEspecialidades {
                last_publication_date(locale: "pt-BR")
                first_publication_date(locale: "pt-BR")
              }
            }
          }
          texto_da_novidade {
            text
          }
        }
        open_graph {
          og_description {
            text
          }
          og_title {
            text
          }
          og_image {
            url
          }
        }
        canonical_url {
          text
          html
        }
        foto_destaque {
          alt
          copyright
          url
          gatsbyImageData
        }
        titulo_da_pagina {
          richText
        }
        descricao_curta {
          richText
        }

        # REFACTOR sliceZone
        body {
          ... on PrismicHomepageDataBodySecaoInstitucional {
            id
            slice_type
            firstBlock: primary {
              titulo_institucional_01 {
                richText
              }
              subtitulo_institucional_01 {
                richText
              }
              texto_institucional {
                richText
              }
              botao_institucional_texto {
                text
              }
              botao_institucional_link {
                uid
              }
            }
            secondBlock: primary {
              subtitulo_institucional_02 {
                richText
              }
              diferencial_01 {
                richText
              }
              texto_diferencial_01 {
                richText
              }
              diferencial_02 {
                richText
              }
              texto_diferencial_02 {
                richText
              }
              diferencial_03 {
                richText
              }
              texto_diferencial_03 {
                richText
              }
              botao_diferencial___texto {
                text
              }
              botao_diferencial___link {
                uid
              }
            }
            images: primary {
              galeria___imagem_1 {
                gatsbyImageData
              }
              galeria___imagem_2 {
                gatsbyImageData
              }
              galeria___imagem_3 {
                gatsbyImageData
              }
              galeria___imagem_4 {
                gatsbyImageData
              }
              galeria___imagem_5 {
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomepageDataBodySecaoDeExames {
            slice_type
            primary {
              titulo_exames {
                richText
              }
              texto_exames {
                richText
              }
              botao_exames_link {
                uid
              }
              botao_exames___texto {
                text
              }
            }
            items {
              exame_destacado {
                uid
                document {
                  ... on PrismicExames {
                    id
                    uid
                    data {
                      body {
                        ... on PrismicExamesDataBodyHero {
                          id
                          primary {
                            nome_do_exame {
                              text
                            }
                          }
                        }
                        ... on PrismicExamesDataBodySobre {
                          id
                          primary {
                            sobre_o_exame {
                              richText
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodySecaoDeEspecialidades {
            id
            slice_type
            primary {
              titulo_especialidade {
                richText
              }
              texto_especialidade {
                richText
              }
              botao_especialidade_texto {
                text
              }
              botao_especialidade_link {
                slug
                uid
              }
            }
            items {
              especialidade_destacada {
                document {
                  ... on PrismicEspecialidade {
                    pageURL: uid
                    data {
                      icone_da_especialidade {
                        url
                      }
                      nome_da_especialidade {
                        richText
                      }
                      amountExams: body {
                        ... on PrismicEspecialidadeDataBodySecaoDeExames {
                          items {
                            seletor_de_exames {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    specialties: allPrismicEspecialidade(
      sort: { order: ASC, fields: data___nome_da_especialidade___text }
    ) {
      nodes {
        data {
          nome_da_especialidade {
            richText
          }
          icone_da_especialidade {
            url
          }
        }
        url: uid
      }
    }
    contentContacts: prismicContato {
      data {
        telefone_1 {
          text
        }
        email {
          text
        }
        descricao {
          text
        }
      }
    }
    sectionExams: allPrismicHomepage {
      data: nodes {
        item: data {
          content: body {
            ... on PrismicHomepageDataBodySecaoDeExames {
              id
              primary {
                botao_exames___texto {
                  richText
                }
                botao_exames_link {
                  slug
                  uid
                }
                texto_exames {
                  richText
                }
                titulo_exames {
                  richText
                }
              }
            }
          }
        }
      }
    }
    sectionQuality: prismicQualidade {
      data {
        titulo_da_secao {
          richText
        }
        descricao_curta {
          richText
        }
        imagem_destacada {
          gatsbyImageData
        }
        certificates: body {
          ... on PrismicQualidadeDataBodySecaoDeCertificados {
            items {
              nome_do_certificado
              anexo_do_certificado {
                url
              }
            }
          }
        }
      }
    }
    searchExams: prismicBuscarExames {
      ...BuscarExamesFragment
    }
    allExams: allPrismicExames {
      nodes {
        data {
          body {
            ... on PrismicExamesDataBodyHero {
              id
              items {
                sinonimo {
                  text
                }
              }
              primary {
                codigo_sollutio {
                  text
                }
                nome_do_exame {
                  text
                }
                codigo_tuss {
                  text
                }
              }
            }
          }
        }
        uid
      }
    }
    ctaContato: prismicCtaContato {
      ...CTAContatoFragment
    }
  }
`;

export default IndexPage;
// export default withPrismicPreview(IndexPage({ data }: IndexPageParams)
//   , [
//   {
//     repositoryName: process.env.GATSBY_PRISMIC_REPO ?? "",
//     linkResolver
//   }
// ]);
